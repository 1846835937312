// モバイル用メニュー
jQuery(function(){
	jQuery('#snav').simplerSidebar({
		selectors: {
			trigger: '.js-toggle-snav',
			quitter: '.close'
		},
		sidebar: {
			width: 300
		},
		animation: {
			easing: 'easeOutQuint'
		},
		freezePage: false
	});
});

// Summary ホバー時に画像をフェードで切替（接尾語link, hover）
jQuery(document).ready(function(){
	if(jQuery.support.opacity){
		var fadeSpeed = 400;
		var rolloverImg = jQuery('a img');
		rolloverImg.each(function(){
			if(this.src.match('-link')){
				var imgWidth = jQuery(this).width();
				var imgHeight = jQuery(this).height();
				jQuery(this).parent('a').css({display: 'inline-block', width: imgWidth, height: imgHeight});
				this.onImgSrc = new Image();
				this.onImgSrc.src = this.getAttribute('src').replace('-link', '-hover');
				jQuery(this.onImgSrc).css({position: 'absolute', opacity: 0} );
				jQuery(this).before(this.onImgSrc);
				jQuery(this.onImgSrc).mousedown(function(){
					jQuery(this).stop().animate({opacity: 0},{duration: fadeSpeed, queue: false});
				});
				jQuery(this.onImgSrc).hover(
					function(){ jQuery(this).animate({opacity: 1},{duration: fadeSpeed, queue: false}); },
					function(){ jQuery(this).animate({opacity: 0},{duration: fadeSpeed, queue: false}); }
					);
			}
		});
	}else{
		jQuery('a[href] img, input[type="image"]').mouseover(function(){
			jQuery(this).attr('src', jQuery(this).attr('src').replace('-link', '-hover'));
			jQuery(this).mouseout(function(){
				jQuery(this).attr('src', jQuery(this).attr('src').replace('-hover', '-link'));
			});
		});
	}
});

// Summary ページトップへ（class="pageop"）
jQuery(document).ready(function(){
	var pagetop = jQuery('.pagetop');
	jQuery(window).scroll(function(){
		if (jQuery(this).scrollTop() > 800){
			pagetop.fadeIn();
		} else{
			pagetop.fadeOut();
		}
	});
	pagetop.click(function(){
		jQuery('body, html').animate({scrollTop: 0}, 500);
		return false;
	});
});


// 端末確認
jQuery(document).ready(function(){
	if (navigator.userAgent.indexOf('Win') > 0) {
		jQuery('html').addClass('win');
		jQuery('body').addClass('win');
	} else if (navigator.userAgent.indexOf('Mac') > 0){
		jQuery('html').addClass('mac');
		jQuery('body').addClass('mac');
	}
});


// 環境
jQuery(document).ready(function(){
	var __add = function(name, ver){
		if(document.documentElement.className){ document.documentElement.className += ' '; }
		document.documentElement.className += name + (ver!='' ? ' ' + name+(ver*1).toString().replace('.','_') : '');
	}
	var userAgent = window.navigator.userAgent.toLowerCase();
	var appVersion = window.navigator.appVersion.toLowerCase();
	// if( get = userAgent.match( /msie (\d+(\.\d+)?)/i ) )              { __add('ie',      get[1]); }
	// else if( get = userAgent.match( /Trident.+rv\:(\d+(\.\d+)?)/i ) ) { __add('ie',      get[1]); }
	// else if( get = userAgent.match( /chrome\/(\d+(\.\d+)?)/i ) )      { __add('chrome',  get[1]); }
	// else if( get = userAgent.match( /firefox\/(\d+(\.\d+)?)/i ) )     { __add('firefox', get[1]); }
	// else if( get = userAgent.match( /opera\/(\d+(\.\d+)?)/i ) )       { __add('opera',   get[1]); }
	// else if( get = userAgent.match( /safari\/(\d+(\.\d+)?)/i ) )      { __add('safari',  get[1]); }
	// else if( get = userAgent.match( /Mobile Safari\/(\d+(\.\d+)?)/i ) )      { __add('safari',  get[1]); }
	// if( get = userAgent.match( /iPhone OS (\d+(\.\d+)?)/i ) )    { __add('ios',     get[1]); }
	// if( get = userAgent.match( /iPhone;/i ) )                    { __add('iphone',  ''); }
	// else if( get = userAgent.match( /iPod;/i ) )                 { __add('ipod',    ''); }
	// else if( get = userAgent.match( /iPad;/i ) )                 { __add('ipad',    ''); }
	// else if( get = userAgent.match( /Android (\d+(\.\d+)?)/i ) ) { __add('android', get[1]); }

	if( get = userAgent.match( /msie (\d+(\.\d+)?)/i ) )              { __add('ie',      get[1]); }
	else if( get = userAgent.match( /trident.+rv\:(\d+(\.\d+)?)/i ) ) { __add('ie',      get[1]); }
	else if( get = userAgent.match( /chrome\/(\d+(\.\d+)?)/i ) )      { __add('chrome',  get[1]); }
	else if( get = userAgent.match( /firefox\/(\d+(\.\d+)?)/i ) )     { __add('firefox', get[1]); }
	else if( get = userAgent.match( /opera\/(\d+(\.\d+)?)/i ) )       { __add('opera',   get[1]); }
	else if( get = userAgent.match( /safari\/(\d+(\.\d+)?)/i ) )      { __add('safari',  get[1]); }
	else if( get = userAgent.match( /mobile safari\/(\d+(\.\d+)?)/i ) )      { __add('safari',  get[1]); }
	if( get = userAgent.match( /iphone os (\d+(\.\d+)?)/i ) )    { __add('ios',     get[1]); }
	if( get = userAgent.match( /iphone;/i ) )                    { __add('iphone',  ''); }
	else if( get = userAgent.match( /ipod;/i ) )                 { __add('ipod',    ''); }
	else if( get = userAgent.match( /ipad;/i ) )                 { __add('ipad',    ''); }
	else if( get = userAgent.match( /android (\d+(\.\d+)?)/i ) ) { __add('android', get[1]); }

});


// ヘッダー固定 - htmlに余白設定

// ヘッダー固定 - htmlに余白設定
jQuery(function() {
	var jQuerywin = jQuery(window);
	var header = jQuery('header');
	var animationStartPosition = jQuery('main').offset();
	var animationStartPosition = animationStartPosition.top;
	var animationClass = 'animation';
	jQuerywin.on('load scroll', function() {
		var value = jQuery(this).scrollTop();
		if ( value > animationStartPosition ) {
			header.addClass(animationClass);
		} else {
			header.removeClass(animationClass);
		}
	});
});

jQuery(function(){
	var headerHeight = jQuery('header').outerHeight();
	var ipadStyle = '';
	ipadStyle += '<style>';
	ipadStyle += 'html.safari:after { top:' + headerHeight + 'px; }';
	ipadStyle += '</style>';
	// console.log(ipadStyle);
	jQuery('head').append(ipadStyle);
});